import './App.css';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import abi from "./abi.json";
import logo from './assets/logo.png';
import gif from './assets/lonking.gif';
import Web3 from "web3";
require("dotenv").config();
const { REACT_APP_CONTRACT_ADDRESS, REACT_APP_BASE_URL } = process.env;

function App() {

  const [loader, setLoader] = useState(true);
  const [showMintButton, setShowMintButton] = useState(false);

  const [whitelistMinted, setWhitelistMinted] = useState(0);

  const [isPaused, setIsPaused] = useState(true);

  const [number, setNumber] = useState(1);
  const [merkel, setMerkel] = useState();
  const [rootHash, setRootHash] = useState();

  const[userWhiteListCounter, setUserWhiteListCounter] = useState(0);

  const [contract, setContract] = useState(null);
  const [tokenId, setTokenId] = useState(null);
  const [supply, setTokenSupply] = useState(null);

  const [whiteListPrice, setWhiteListePrice] = useState();
  const [whiteListPriceInEth, setWhiteListPriceInEth] = useState(0.055);
  
  const [publicPrice, setPublicPrice] = useState();
  const [publicPriceInEth, setPublicPriceInEth] = useState(0.077);
  
  const [minted, setMinted] = useState(false);
  const [walletConnected, setWalletConnected] = useState(false);

  useEffect(() => {
    loadWeb3();
  }, []);

  async function loadWeb3() {
    if (window.ethereum) {
      window.web3 = new Web3(window.ethereum);
      const web3 = window.web3;
      const contractaddress = REACT_APP_CONTRACT_ADDRESS;
      
      const contract = new web3.eth.Contract(abi, contractaddress);
      setContract(contract);
      console.log(contract, 'contract');

      // WHITELIST PRICE
      let whiteListPrice = await contract.methods.preSalePrice().call();
      setWhiteListePrice(whiteListPrice);
      setWhiteListPriceInEth(web3.utils.fromWei(whiteListPrice, "ether"));

      // PUBLIC PRICE
      let publicPrice = await contract.methods.publicSaleprice().call();
      setPublicPrice(publicPrice);
      setPublicPriceInEth(web3.utils.fromWei(publicPrice, "ether"));

      // TOTAL SUPPLY
      const totalSupply = await contract.methods.totalSupply().call();
      setTokenSupply(totalSupply);

      const isPaused = await contract.methods.isSalePaused().call();
      console.log(isPaused, 'isPaused');
      setIsPaused(isPaused);

      // GETTING USER WHITE LIST COUNTER
      const address = await web3.eth.getAccounts();
      console.log(address, 'address');
      if (address.length > 0) {
        const userInContractList = await contract.methods._preSaleAddresses(address[0]).call();
        if (userInContractList && userInContractList.counter) {
          setWhitelistMinted(parseInt(userInContractList.counter));
        }

        // CHECKING USER'S EXISTENCE IN LIST
        const checkListRes = await checkUserList(address.toString());
        setShowMintButton(checkListRes.data.isExisting);
      }

      setLoader(false);

    } else if (window.web3) {
      window.web3 = new Web3(window.web3.currentProvider);
    } else {
      window.alert(
        "Non-Ethereum browser detected. You should consider trying MetaMask!"
      );
    }
  }
  async function whiteListMint() {
    setLoader(true);
    try {
      const web3 = window.web3;
      const _value = whiteListPrice;
      const address = await web3.eth.getAccounts();
      const merkelResponse = await getMerkelProof(address.toString());
      const rootHash = merkelResponse.data.rootHash;
      const merkleProof = merkelResponse.data.merkleProof;
      await contract.methods
        .whiteListMint(merkleProof, rootHash, number)
        .send({ from: address.toString(), value: (_value * number) });

      const totalSupply = await contract.methods.totalSupply().call();
      setTokenSupply(totalSupply);
      setNumber(1);

      // GETTING USER WHITE LIST COUNTER
      const userInContractList = await contract.methods._preSaleAddresses(address[0]).call();
      if (userInContractList && userInContractList.counter) {
        setWhitelistMinted(parseInt(userInContractList.counter));
      }
      setLoader(false);
    } catch (e) {
      setLoader(false);
    }
  }

  async function normalMint() {
    setLoader(true);
    try {
      const web3 = window.web3;
      const _value = publicPrice;
      const address = await web3.eth.getAccounts();
      await contract.methods
        .mint(number)
        .send({ from: address.toString(), value: (_value * number) });

      const totalSupply = await contract.methods.totalSupply().call();
      setTokenSupply(totalSupply);
      setNumber(1);

      setLoader(false);
    } catch (e) {
      setLoader(false);
    }
  }

  async function connectWallet() {
    if (window.ethereum) {
      window.web3 = new Web3(window.ethereum);
      await window.ethereum.enable();
      const web3 = window.web3;
      const metaMaskAccount = await web3.eth.getAccounts();
      // setConnectedstatus(true);
      let splitedMetaMaskAddress;
      if (metaMaskAccount) {
        splitedMetaMaskAddress =
          metaMaskAccount[0].substring(0, 6) +
          "......" +
          metaMaskAccount[0].substring(
            metaMaskAccount[0].length - 4,
            metaMaskAccount[0].length
          );
      }
      setWalletConnected(true);
      const address = await web3.eth.getAccounts();
      console.log(address, 'address');
      if (address.length > 0) {
        const userInContractList = await contract.methods._preSaleAddresses(address[0]).call();
        if (userInContractList && userInContractList.counter) {
          setWhitelistMinted(parseInt(userInContractList.counter));
        }

        // CHECKING USER'S EXISTENCE IN LIST
        const checkListRes = await checkUserList(address.toString());
        setShowMintButton(checkListRes.data.isExisting);
      }
    } else if (window.web3) {
      window.web3 = new Web3(window.web3.currentProvider);
    } else {
      window.alert(
        "Non-Ethereum browser detected. You should consider trying MetaMask!"
      );
    }
  }

  async function getMerkelProof(addr) {
    return await axios.post(`${REACT_APP_BASE_URL}/api/getWhitelistMerkel`, {senderAddress: addr});
  }

  async function checkUserList(addr) {
    return await axios.post(`${REACT_APP_BASE_URL}/api/checkUserInList`, {senderAddress: addr});
  }

  function incriment() {
    if (number < 20) {
      setNumber(prevNumber => prevNumber + 1);
    }
  }
  function decrement() {
    if (number > 1) {
      setNumber(prevNumber => prevNumber - 1);
    }
  }

  return (
    <div className="App">
      <img src={logo} className="logo" />
      <div className='mintContainer'>
        <div className="heading">The Happy Lions Circle</div>
        <div className="description">7777 randomly generated Happy Lions available to mint for FREE + gas.</div>
        <div className="minting">
          <div className="row">
            {loader ? 
            <div className='col-lg-6 col-md-6 col-sm-12 col-xs-12 m-auto loader'></div>
            :
            isPaused ?
              <div className='col-lg-6 col-md-6 col-sm-12 col-xs-12 isPaused'>Minting Will Begin Soon</div>
            :
            <div className='col-lg-6 col-md-6 col-sm-12 col-xs-12 m-auto'>
              <div className="flexLayout">
                <div className="incrimentButton" onClick={decrement}>-</div>
                <div className="mintNumber">{number}</div>
                <div className="incrimentButton" onClick={incriment}>+</div>
                <div className="totalMinted">{supply} / 7777 MINTED</div>
              </div>
              {!walletConnected ?
                <div className="mintNow" onClick={connectWallet}>Connect Wallet</div>
              :
                <div className="flexLayout">
                  <div className="mintNow" onClick={normalMint}>Mint</div>
                  {showMintButton && (2 - whitelistMinted > 0 ) ?
                  <div onClick={whiteListMint} className={`mintNow ${number + whitelistMinted <= 2 ? "" : "disabled"}`}>Whitelist Mint</div>
                  :
                  <div></div>
                  }
                </div> 
              }
              {showMintButton && (2 - whitelistMinted > 0 ) ?
              <div className="whitelistRemaining">
                You Have {2 - whitelistMinted} Whitelist Mint Remaining
              </div>
              :
              <div></div>
              }
            </div>
            }
            <div className='col-lg-6 col-md-6 col-sm-12 col-xs-12 flexLayout'>
              <img src={gif} className="nftGif"></img>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
